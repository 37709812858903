export const aboutMenu = [
  { title: '公司简介', index: 1 },
  { title: '投资理念', index: 2 },
  { title: '企业文化', index: 3 },
  { title: '专业团队', index: 4 }
]

export const costMenu = [
  { title: '申赎程序', index: 1 },
  { title: '风控系统', index: 2 },
  { title: '旗下基金', index: 3 }
]

export const wisdomMenu = [
  { title: '投资者教育', index: 1 },
  { title: '千门公告', index: 2 }
]

export const contactMenu = [
  { title: '联系方式', index: 1 }
]