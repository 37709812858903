<template>
  <div class="contact">
    <img src="@/assets/contact_banner.png" alt="" style="width: 100%">
    <div class="right__wrap">
      <Sider :menuList="contactMenu" :title="'联系我们'" @handleMenu="changeMenu"/>
      <div class="content">
        <div>
          <div class="contact_content">
            <img src="@/assets/contact_img1.png" alt="" class="cot_title">
            <img src="@/assets/contact_img2.png" alt="" style="width: 100%">
          </div>
          <div class="cot_text">
            <img src="@/assets/contact_img3.png" class="cot_icon" alt="">
            地址：上海市静安区江场路1228弄10号1703室
          </div>
          <div class="cot_text">
            <img src="@/assets/contact_img4.png" class="cot_icon" alt="">
            电话：021-63063966
          </div>
          <div class="cot_text">
            <img src="@/assets/contact_img5.png" class="cot_icon" alt="">
            电子邮箱：qianmanjzfb@126.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sider from '@/components/sider.vue'
import { contactMenu } from './menu.js'
export default {
  components: { Sider },
  data() {
    return {
      contactMenu
    }
  },
  methods: {
    changeMenu(index) {
      this.$router.push({ path: this.$route.path, query: { data: index}})
    }
  }
}
</script>

<style scoped>
.contact {
  background: #f9f9f9;
  width: 100%;
}
.right__wrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
}
.content {
  width: 1000px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(242,242,242,1);
  display: flex;
  padding: 40px 50px 50px 30px;
  box-sizing: border-box;
}
.cot_title {
  width: 358px;
  height: 53px;
  display: block;
  text-align: left;
  margin-bottom: 40px;
}
.contact_content {
  padding-bottom: 40px;
  border-bottom: 1px dashed #F2F2F2;
  margin-bottom: 38px;
}
.cot_text {
  display: flex;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
}
.cot_icon {
  height: 28px;
  width: 28px;
  margin-right: 14px;
}
</style>