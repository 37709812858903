<template>
  <div class="sider">
    <div class="subTitle">{{ title }}</div>
    <div class="line"></div>
    <div v-for="item in menuList" :key="item.title" :class="['subItem', $route.query.data == item.index ? 'subItem_active' : '']" @click="change(item.index)">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    menuList: Array
  },
  methods: {
    change(index) {
      this.$emit('handleMenu', index)
    }
  }
}
</script>


<style scoped>
.sider {
  width: 170px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 51px;
  position: relative;
  margin-right: 30px;
  box-shadow: 0px 0px 8px 0px rgba(242,242,242,1);
  text-align: left;
}
.subTitle {
  font-size: 22px;
  color: #333333;
  line-height: 22px;
  padding-left: 41px;

}
.line {
  width: 40px;
  height: 4px;
  background: #333333;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 41px;

}
.subItem {
  height: 62px;
  color: #333;
  font-size: 16px;
  line-height: 62px;
  cursor: pointer;
  padding-left: 41px;

}
.subItem_active {
  background-color: #F9F9F9;
  color: #AD3026;
  font-weight: 600;
}
</style>