<template>
  <div class="about">
    <img src="@/assets/about_banner.png" class="banner" alt="">
    <div class="right__wrap">
      <Sider :menuList="menuList" :title="'关于千门'" @handleMenu="changeMenu"/>
      <div class="content">
        <template v-if="!$route.query.data || $route.query.data == 1"><Profile /></template>
        <template v-if="$route.query.data == 2"><Idea /></template>
        <template v-if="$route.query.data == 3"><Culture /></template>
        <template v-if="$route.query.data == 4"><Team /></template>
      </div>
    </div>
  </div>
</template>

<script>
import Sider from '@/components/sider.vue'
import Profile from './profiles.vue'
import Idea from './idea.vue'
import Team from './team.vue'
import Culture from './cultrue.vue'
import { aboutMenu } from '../menu.js'
export default {
  components: {
    Sider,
    Profile,
    Idea,
    Team,
    Culture
  },
  data() {
    return {
      menuList: aboutMenu,
    }
  },
  methods: {
    changeMenu(index) {
      this.$router.push({ path: this.$route.path, query: { data: index}})
    }
  }
}
</script>

<style scoped>
.about {
  background: #f9f9f9;
}
.banner {
  width: 100%;
}
.right__wrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
}
.content {
  width: 1000px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(242,242,242,1);
  display: flex;
  padding: 40px 50px 50px 30px;
  box-sizing: border-box;
}
</style>