import { render, staticRenderFns } from "./risk.vue?vue&type=template&id=36e2b339&scoped=true&"
var script = {}
import style0 from "./risk.vue?vue&type=style&index=0&id=36e2b339&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e2b339",
  null
  
)

export default component.exports