<template>
  <div class="notice">
    <template v-if="!directory">
      <img src="@/assets/notice_img1.png" class="notice_img">
      <div class="notice_list" @click="handleBack(3)">
        <div class="title">上海千门资产管理有限公司公司名称、经验范围变更公告</div>
        <div class="time">2022-06-24</div>
      </div>
      <div class="notice_list" @click="handleBack(2)">
        <div class="title">关于千门金花生成长混合私募证券投资基金合同终止及财产清算公告</div>
        <div class="time">2022-06-20</div>
      </div>
      <div class="notice_list" @click="handleBack(1)">
        <div class="title">上海千门资产管理有限公司法定代表人、实际控制人及控股股东变更公告</div>
        <div class="time">2021-12-20</div>
      </div>
    </template>
    <template v-if="detailId == 1">
      <div class="back" @click="handleBack"><img src="@/assets/back.png" class="back_img"/>返回</div>
      <div class="detail_title">上海千门资产管理有限公司法定代表人、实际控制人及控股股东变更公告</div>
      <img src="@/assets/detail_img.png" class="detail_img" alt="">
    </template>
    <template v-if="detailId == 2">
      <div class="back" @click="handleBack"><img src="@/assets/back.png" class="back_img"/>返回</div>
      <div class="detail_title">关于千门金花生成长混合私募证券投资基金合同终止及财产清算公告</div>
      <img src="@/assets/detail_img2.jpg" class="detail_img" alt="">
      <img src="@/assets/detail_img3.jpg" class="detail_img" alt="">
      <img src="@/assets/detail_img4.jpg" class="detail_img" alt="">
    </template>
    <template v-if='detailId == 3'>
      <div class="back" @click="handleBack"><img src="@/assets/back.png" class="back_img"/>返回</div>
      <div class="detail_title">上海千门资产管理有限公司公司名称、经验范围变更公告</div>
      <img src="@/assets/detail_img5.jpg" class="detail_img" alt="">
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDetail: false,
      detailId: null
    }
  },
  methods: {
    handleBack(id) {
      this.directory = !this.directory
      this.detailId = id || null
    }
  }
}
</script>

<style scoped>
.notice {
  width: 100%;
}
.notice_img {
  width: 276px;
  display: block;
  text-align: left;
  margin-bottom: 30px;
}
.notice_list {
  height: 48px;
  width: 100%;
  border-bottom: 1px dashed #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.title {
  font-size: 18px;
  color: #666;
}
.time {
  font-size: 18px;
  color: #999; 
}
.back {
  font-size: 18px;
  color: #333;
  text-align: left;
  display: flex;
  height: 17.50px;
  align-items: center;
  cursor: pointer;
}
.back_img {
  display: block;
  height: 17.5px;
  width: 9.7px;
  margin-right: 8px;
}
.detail_title {
  font-size: 24px;
  color: #333;
  margin-top: 37px;
  margin-bottom: 40px;
}
.detail_img {
  width: 100%;
}
</style>