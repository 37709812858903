<template>
  <div class="wisdom">
    <img src="@/assets/wisdom_banner.png" alt="" style="width: 100%">
    <div class="right__wrap">
      <Sider :menuList="wisdomMenu" :title="'智慧千门'" @handleMenu="changeMenu"/>
      <div class="content">
        <template v-if="!$route.query.data || $route.query.data == 1"><Education /></template>
        <template v-if="$route.query.data == 2"><Notice /></template>
      </div>
    </div>
  </div>
</template>

<script>
import Sider from '@/components/sider.vue'
import Education from './education.vue'
import Notice from './notice.vue'
import { wisdomMenu } from '../menu.js'
export default {
  components: { Sider, Education, Notice }, 
  data() {
    return {
      wisdomMenu
    }
  },
  methods: {
    changeMenu(index) {
      this.$router.push({ path: this.$route.path, query: { data: index}})
    }
  }
}
</script>

<style scoped>
.wisdom {
  background: #f9f9f9;
}
.right__wrap {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
}
.content {
  width: 1000px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(242,242,242,1);
  display: flex;
  padding: 40px 50px 50px 30px;
  box-sizing: border-box;
}
</style>